import React, { useState } from 'react'
import VisibilitySensor from 'react-visibility-sensor';
import CCLButton from '../../components/cclButton';
import './cclStats.scss';

const stats = [
  {
    id: 'alumni',
    stat: '750K',
    about: 'Alumni worldwide and counting',
    copy: ''
  },
  {
    id: 'countries',
    stat: '160+',
    about: 'Countries where we\'ve conducted programs',
    copy: ''
  },
  {
    id: 'years',
    stat: '50',
    about: 'Years pioneering the industry’s best practices',
    copy: ''
  },
  {
    id: 'people',
    stat: '80K',
    about: 'People shared their journey with us last year',
    copy: ''
  },
  {
    id: 'orginizations',
    stat: '3K',
    about: 'Organizations partner with us annually',
    copy: ''
  },
  {
    id: 'offices',
    stat: '1k',
    about: 'Experts globally for virtual or in-person support',
    copy: ''
  }
];

const animateValue = (id, endString, end) => {
  let range = end - 0;
  let current = 0;
  let increment = end > 0 ? 1 : -1;
  let stepTime = Math.abs(Math.floor(1000 / range));
  let obj = document.getElementById(id);
  let timer = setInterval(function () {
    current += increment;
    obj.innerHTML = current + endString;
    if (current === end) {
      clearInterval(timer);
    }
  }, stepTime);
}

let counted = false;

const onVisible = isVisible => {
  if (isVisible && !counted) {
    animateValue('alumni', 'K', 750);
    animateValue('countries', '+', 160);
    animateValue('years', '', 50);
    animateValue('people', 'K', 80);
    animateValue('orginizations', 'K', 3);
    animateValue('offices', 'K', 1);
    counted = true;
  }
}

const CCLStats = ({ isVisible }) => {
  return (
    <div className="ccl-stats">
      <div className="stats">
        <h2>
          Leadership never stops.
          <br />
          Neither does our commitment to you.
        </h2>
        <VisibilitySensor onChange={onVisible}>
          <ul className="stats-content">
            {stats.map(stat => (
              <li className="ccl-stat" key={stat.stat}>
                <h2 id={stat.id}>{stat.stat}</h2>
                <h4>{stat.about}</h4>
                <p>{stat.copy}</p>
              </li>
            ))}
          </ul>
        </VisibilitySensor>
        <a href="#ccl-trends">
          <CCLButton label="Start your transformation" type="submit" style="primary" arrow="true"></CCLButton>
        </a>
        <a href="#ccl-journey-form" className="pl-10">
          <CCLButton label="Talk to an Expert" type="submit" style="primary" arrow="true"></CCLButton>
        </a>
      </div>
    </div>
  );
};

export default CCLStats;
